import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/pageTemplate.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`In opsdroid, we were trying to update pyyaml to version 4.2b1 in order to fix the security vulnerability of version 3.12 that allows users to run python code from within a `}<inlineCode parentName="p">{`.yaml`}</inlineCode>{` file. The fix was rather easy, we simply had to replace `}<inlineCode parentName="p">{`yaml.loader(stream)`}</inlineCode>{` to `}<inlineCode parentName="p">{`yaml.loader(stream, Loader=SafeLoader)`}</inlineCode>{` but I wanted to add a test that shows that this fix does work.`}</p>
    <p>{`One of the first things we do in opsdroid is to load the file `}<inlineCode parentName="p">{`config.yaml`}</inlineCode>{` to get the configuration for the bot. Before the update, you could run python code from within the config.yaml like this `}<inlineCode parentName="p">{`test: !!python/object/apply:os.system ["echo 'Oops!';"]`}</inlineCode>{` - `}<em parentName="p">{`this will print Oops! into the shell`}</em></p>
    <p>{`After the update and the fix, when a user tries to run python code from within a yaml file the following happens:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`could not determine a constructor for the tag 'tag:yaml.org,2002:python/object/apply:os.system'
in "/Users/<user>/Library/Application Support/opsdroid/configuration.yaml"
#sys.exit(1) called
`}</code></pre>
    <p>{`Since the next version of pyyaml might change how things work, I wanted to create a test to check if everything would work as expected and no python code could be run from within a yaml file.`}</p>
    <p><strong parentName="p">{`There was only one problem.`}</strong></p>
    <p>{`Unittest calls `}<inlineCode parentName="p">{`sys.exit()`}</inlineCode>{` when all the tests finish running, so the test that I created was causing all sort of issues with the rest of the tests - some of them were passing but most of them failed.`}</p>
    <p>{`After a bit of research on StackOverflow and a bit of trial and error I came up with a way to test the fix and make the rest of the tests work properly.`}</p>
    <h1 {...{
      "id": "the-test",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-test",
        "aria-label": "the test permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The Test`}</h1>
    <p>{`I came across this post on tutorials point - `}<a parentName="p" {...{
        "href": "https://www.tutorialspoint.com/How-do-you-test-that-a-Python-function-throws-an-exception"
      }}>{`How do you test that a Python function throws an exception`}</a>{`, Manogna suggested to add `}<inlineCode parentName="p">{`unittest.main(exit=False)`}</inlineCode>{` to the test and that solved my issues.`}</p>
    <p>{`So the test ended up looking like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`def test_load_exploit(self):
    opsdroid, loader = self.setup()
    with self.assertRaises(SystemExit):
        config = loader.load_config_file(
            [os.path.abspath("tests/configs/include_exploit.yaml")])
        self.assertLogs('_LOGGER', 'critical')
        self.assertRaises(YAMLError)
        unittest.main(exit=False)
`}</code></pre>
    <p>{`Basically, this test does five things:`}</p>
    <ol>
      <li parentName="ol">{`loads a yaml file that contains `}<inlineCode parentName="li">{`!!python/object/apply:os.system ["echo 'Oops!';"]`}</inlineCode></li>
      <li parentName="ol">{`asserts if our Logger will log a critical message`}</li>
      <li parentName="ol">{`asserts if `}<inlineCode parentName="li">{`yaml.YAMLError`}</inlineCode>{` is raised`}</li>
      <li parentName="ol">{`asserts if `}<inlineCode parentName="li">{`SystemExit`}</inlineCode>{` is raised - due to sys.exit() call`}</li>
      <li parentName="ol">{`prevents unittest from exiting`}</li>
    </ol>
    <h1 {...{
      "id": "the-code",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#the-code",
        "aria-label": "the code permalink",
        "className": "anchor before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`The code`}</h1>
    <p>{`The code that these test covers is shown below, I decided to add it here just in case you want to know exactly what the code does and hopefully, it can help you somehow.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-python"
      }}>{`try:
    with open(config_path, 'r') as stream:
        _LOGGER.info(_("Loaded config from %s."), config_path)
        return yaml.load(stream, Loader=yaml.SafeLoader)
except yaml.YAMLError as error:
    _LOGGER.critical(error)
    sys.exit(1)
except FileNotFoundError as error:
    _LOGGER.critical(error)
    sys.exit(1)
`}</code></pre>
    <p>{`As you can see, we try to open the yaml file and load it using `}<inlineCode parentName="p">{`yaml.load(stream, Loader=yaml.SafeLoader)`}</inlineCode>{`, but if an exception is raised we just log the error and call sys.exit since the bot can't operate without any configuration.`}</p>
    <p>{`Finally, if you would like to check the whole project, have a look at `}<a parentName="p" {...{
        "href": "https://github.com/opsdroid/opsdroid"
      }}>{`opsdroid on GitHub`}</a>{` we are always looking for new contributors no matter the experience!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      